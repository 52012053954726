import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageLayout from '@rentivo/gatsby-theme-barcelona/src/components/layouts/PageLayout';
import { useMenusAndBlocksData } from '@rentivo/gatsby-theme-barcelona/src/hooks/usePageData';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { useSelector } from 'react-redux';
import PageNotFoundMessage from '@rentivo/gatsby-core/src/components/generic/PageNotFoundMessage';
import PageNotFoundProvider from '@rentivo/gatsby-core/src/containers/PageNotFoundProvider';
import PropertyLayout from '@rentivo/gatsby-theme-barcelona/src/templates/Property/components/PropertyLayout';

const PageNotFound = (props) => {
  const { data: { globalContext: { menusAndBlocksString } } } = props;
  if(!menusAndBlocksString) return '404';
  const menusAndBlocksObj = JSON.parse(menusAndBlocksString);
  const currentLang = useSelector(state => selectLang(state));
  const defaultLang = useSelector(state => selectDefaultLang(state));

  const pageContext = (menusAndBlocksObj[currentLang]) ? menusAndBlocksObj[currentLang] : (menusAndBlocksObj[defaultLang]) ? menusAndBlocksObj[defaultLang] : null;
  if(!pageContext) return '404';

  const menusAndBlocks = useMenusAndBlocksData({pageContext});

  return (
    <PageNotFoundProvider propertyComponent={<PropertyLayout {...menusAndBlocks}/>}>
      <PageLayout {...menusAndBlocks}>
        <PageNotFoundMessage/>
      </PageLayout>
    </PageNotFoundProvider>
  );
};

PageNotFound.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageNotFound;

export const pageQuery = graphql`
  query PageNotFoundQuery {
    globalContext {
      menusAndBlocksString
    }
  }
`;
