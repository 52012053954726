import React from 'react';
import get from 'lodash/get';
import useAxios from 'axios-hooks';
import { PropertyProvider } from '@rentivo/gatsby-core/src/context/PropertyContext';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import PricingProvider from '@rentivo/gatsby-core/src/containers/PricingProvider';


const PageNotFoundProviderComponent = ({children, propertyComponent, isProperty404, axiosConfig}) => {
  if(!isProperty404 || !propertyComponent) return children;
  const [{ data, loading, error }] = useAxios(axiosConfig);
  if(loading) return <Loader/>;

  if(data && !error && !loading) {
    const property = get(data, 'hits.hits.0', null);
    if(property) {
      const propertyObj = {id: property._id, ...property._source};
      return (
        <PropertyProvider property={propertyObj}>
          <PricingProvider property={propertyObj}>
            {propertyComponent}
          </PricingProvider>
        </PropertyProvider>
      );
    }
  }

  return children;
};

export default PageNotFoundProviderComponent;
