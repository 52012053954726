import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PageNotFoundMessage from '@rentivo/gatsby-core/src/components/generic/PageNotFoundMessage/component';
import { connect } from 'react-redux';
import {
  selectRoutePathsConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  selectRoutePathsConfig,
  (routePaths) => ({
    routePaths
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

PageNotFoundMessage.defaultProps = {
  title: null,
  subtitle: null,
  message: null
};

export default enhance(PageNotFoundMessage);
