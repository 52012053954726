import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet/es/Helmet';
import { Button, Container, LocaleLink } from '@rentivo/gatsby-core';
import { useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/generic/PageNotFoundMessage/messages';
import { StyledPageNotFound, StyledPageNotFoundInner } from '@rentivo/gatsby-core/src/components/generic/PageNotFoundMessage/styles';

const PageNotFoundMessageComponent = ({routePaths, title, subtitle, message, action, className}) => {
  const { formatMessage } = useIntl();
  title = (title) ? title : formatMessage(messages.title);
  subtitle = (subtitle) ? subtitle : formatMessage(messages.subtitle);
  message = (message) ? message : formatMessage(messages.message);

  return (
    <StyledPageNotFound>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subtitle} />
      </Helmet>
      <Container className={className}>
        <StyledPageNotFoundInner>
          <Heading as="h1" size="3xl">{title}</Heading>
          <Heading as="h2" size="1xl" mb={4}>{subtitle}</Heading>
          <Text mb={4}>{message}</Text>
          {action ? action : <LocaleLink to={`/${routePaths.ROUTE_BASE_SEARCH}`}><Button type="primary">Search properties</Button></LocaleLink>}
        </StyledPageNotFoundInner>
      </Container>
    </StyledPageNotFound>
  );
};

export default PageNotFoundMessageComponent;
