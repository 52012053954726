import { chakra } from '@chakra-ui/react';

export const StyledLayout = chakra('div', {
  baseStyle: {
    overflow: "hidden"
  }
});

export const StyledLayoutContent = chakra('section', {
  baseStyle: {

  }
});
