import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PageNotFoundProvider from '@rentivo/gatsby-core/src/containers/PageNotFoundProvider/component';
import { createSelector } from 'reselect';
import {
  selectElasticSearchConfig,
  selectRoutePathsConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';

PageNotFoundProvider.defaultProps = {
  property: null,
  propertyComponent: null
};

const mapStateToProps = (state, { location: { pathname }}) => createSelector(
  selectElasticSearchConfig,
  selectRoutePathsConfig,
  selectSiteNameConfig,
  (elasticSearchConfig, routePaths, siteName) => {
    const propertyEndpoint = `${elasticSearchConfig.url}/${elasticSearchConfig.app}/_search`;
    const propertyId = pathname.replace(/\\(.)/mg, "$1");

    const isProperty404 = (pathname.includes(`/${routePaths.ROUTE_BASE_PROPERTY}`) || pathname.includes(`/${routePaths.ROUTE_BASE_PROPERTY_PUBLIC}`));
    let axiosConfig = null;

    if(typeof btoa !== 'undefined' && isProperty404) {
      axiosConfig = {
        method: 'post',
        baseURL: propertyEndpoint,
        data: JSON.stringify({
          "size": 1,
          "query": {
            "bool": {
              "should": [
                { "term" : { "_id": propertyId } },
                { "term" : { "$marketing.slug.keyword": propertyId } },
                { "term" : { "$marketing.rentivoSlug.keyword": propertyId } }
              ]
            }
          }
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-ndjson',
          'X-App': siteName,
          'Authorization': 'Basic ' + btoa(elasticSearchConfig.credentials),
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      };
    }

    return {
      isProperty404,
      propertyEndpoint: `${elasticSearchConfig.url}/${elasticSearchConfig.app}/_search`,
      routePaths,
      axiosConfig: axiosConfig
    };
  }
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
);

export default enhance(PageNotFoundProvider);
