import { defineMessages } from 'react-intl';

export const scope = 'components.generic.PageNotFoundMessage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '404',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Page not found',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage: 'The page you are looking for is no longer here. Maybe it was never here in the first place. In any case, we are sorry you were sent on this wild goose chase, and have already taken steps to have the person responsible fired.'
  },
});
