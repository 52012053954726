import React from 'react';
import HeaderNavbar from '@rentivo/gatsby-core/src/components/ui/HeaderNavbar';
import Footer from '@rentivo/gatsby-theme-barcelona/src/components/navigation/Footer';
import UpdateBar from '@rentivo/gatsby-core/src/components/ui/UpdateBar';
import TopBar from '@rentivo/gatsby-core/src/components/ui/TopBar';
import { StyledLayout, StyledLayoutContent } from '@rentivo/gatsby-theme-barcelona/src/components/layouts/styles';

const PageLayoutComponent = ({children, footer, updateBar, topBar, menuItems}) => {
  return (
    <StyledLayout id="page-layout" className="layout">
      {(updateBar && updateBar.length > 0) && (
        <UpdateBar blocks={updateBar}/>
      )}
      {(topBar && topBar.length > 0) && (
        <TopBar blocks={topBar}/>
      )}
      <HeaderNavbar {...menuItems}/>
      <StyledLayoutContent className="layout-content">
        {children}
      </StyledLayoutContent>
      {footer && <Footer blocks={footer}/>}
    </StyledLayout>
  )
};

export default PageLayoutComponent;
// <Navbar menuItems={menuItems}/>
