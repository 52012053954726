import { chakra } from '@chakra-ui/react';

export const StyledPageNotFound = chakra('div', {
  baseStyle: {
    mt: '100px',
    mb: '100px',
    'h1': {
      fontSize: '120px',
      mb: 0
    }
  }
});

export const StyledPageNotFoundInner = chakra('div', {
  baseStyle: {
    maxW: '450px'
  }
});
